import Link from "next/link";
import Image, { type StaticImageData } from "next/image";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export type Props = {
	title: string;
	logo: StaticImageData;
	href?: string;
	className?: string;
	iconClassName?: string;
	onClick?: () => void;
};

export default function AuthProviderButton({
	logo,
	title,
	onClick,
	href,
	className,
	iconClassName,
}: Props) {
	const baseClass = cn(
		"flex h-11 gap-x-4 items-center text-sm justify-start rounded-sm border border-secondary/10 bg-primary px-4 text-primary-foreground hover:bg-grey/10 xs:gap-x-6 xs:px-10",
		className,
	);

	const children = (
		<>
			<div className="flex items-center justify-center">
				<Image
					draggable="false"
					src={logo}
					alt="img"
					className={cn("h-auto w-6", iconClassName)}
				/>
			</div>

			{/* Note(Curstantine): mt-0.5 visual kerning because the icons aren't exactly 1/1 in rem */}
			<span className="mt-0.5 text-nowrap font-normal">Continue with {title}</span>
		</>
	);

	if (href !== undefined) {
		return (
			<Link href={href} className={baseClass}>
				{children}
			</Link>
		);
	}

	return (
		<Button type="button" onClick={onClick} className={baseClass}>
			{children}
		</Button>
	);
}
